import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Group } from '@yukawa/chain-main-angular-core';
import { InviteFormData } from 'app/comp/admin/settings/account/invite-dialog/invite-dialog.component';
import { Invitation, InvitationFilter, UserInfo } from 'app/types/domain/profile';
import { createCleanedPartial } from 'app/utils/object.utils';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  baseUrl = inject(ConfigService).formatUrl('inviteUrl');
  static defaultFilter:InvitationFilter = {
    pager: {
        firstResult: 0,
        pageSize: 10
    },
    orderBy: 'created',
    orderDir: 'DESC'
}


  invitations = new BehaviorSubject<Invitation[]>([]);
  totalRows = new BehaviorSubject<number>(0);
  public filter: InvitationFilter = {
  }

  constructor(private _httpClient:HttpClient, private _configService: ConfigService) {}

  get invitations$(){
    return this.invitations.asObservable();
  }
  get rows$(){
    return this.totalRows.asObservable();
  }

  getInvitation(token: string){
    return this._httpClient.get(this.baseUrl + '/confirm/invitation', {headers:this.getHeader(token)});
  }

  getProfile(token: string){
    return this._httpClient.get(this.baseUrl + '/confirm/user', {headers:this.getHeader(token)});
  }

  acceptInvitation(token: string, formData:{firstname:string, lastname:string, password:string}):Observable<UserInfo>{
    return this._httpClient.post(this.baseUrl + '/confirm/accept', {
      password: formData.password,
      user: {
        firstName:formData.firstname,
        lastName:formData.lastname,
      }
    }, {headers:this.getHeader(token)});
  }

  declineInvitation(token: string):Observable<any>{
    return this._httpClient.post(this.baseUrl + '/confirm/refuse', {}, {headers:this.getHeader(token)});
  }

  getHeader(token: string){
    return {
      'Authorization': 'Bearer ' + token
    }
  }
  queryInvitations(filter:InvitationFilter=InvitationService.defaultFilter):Subscription{
    
    filter= Object.assign({},filter);

    Object.entries(filter).forEach(([key, value]) => {
      switch (key) {
          case 'invitedEmail':
          case 'keyword':
                  filter[key] = "*"+value+"*";
              break;
      }
    })
    return this._httpClient.post<QueryResult<Invitation>>(this.baseUrl + '/query',filter)
    .pipe(
      tap(invitations => {
      this.invitations.next(invitations.items);
      this.totalRows.next(invitations.rows);
    })).subscribe();
  }

  cancelInvitation(inv: Invitation){
    return this._httpClient.post(this.baseUrl + '/cancel',{} ,{params:{key: inv.invitationId!}});
  }

  resendInvitation(inv: Invitation){ 
    return this._httpClient.post(this.baseUrl + '/resend',{} ,{params:{key: inv.invitationId!}});
  } 

  createInvite(data: InviteFormData): Observable<Invitation>{
    let invData: {invitedEmail:string, groupNames: string[]|null, remarks:string, company?:any } = {
      invitedEmail: data.email,
      groupNames:data.group.length>0?data.group:null,
      remarks: data.message || ""
    };
    if(data.company!.companyId !== undefined){
      invData.company = {companyId: data.company?.companyId};
    }
    return this._httpClient.post(this.baseUrl + "/create", invData);
  }

  getInvitableGroups(): Observable<Group[]>{
    return this._httpClient.get<Group[]>(this.baseUrl + '/invitable-groups');
  }
}

// https://chem.dev.cloud.yukawa.de/web

// /invitation?token=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhbGV4YW5kZXIubWVyc2RvcmZAeXVrYXdhLWV4cC5jb20iLCJpYXQiOjE3MjkxNTMzMTIsImV4cCI6MTczMDM2MjkxMiwic2NvcGUiOlsiUk9MRV9JTlZJVEVfQ09ORiJdLCJkZXRhaWxzIjp7Imludml0YXRpb25JZCI6MTAxfX0.78ox-MqeJ1TGaOykeXP1_zKvwRQZHLCMhe71-5orUgo